/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import 'ngx-sharebuttons/themes/default';

@include mat.all-component-typographies();
@include mat.core();

$my-app-primary: mat.m2-define-palette(mat.$m2-amber-palette);
$my-app-accent: mat.m2-define-palette(mat.$m2-orange-palette, A200, A100, A400);
$my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-app-theme: mat.m2-define-light-theme(
        (
            color: (
                primary: $my-app-primary,
                accent: $my-app-accent,
                warn: $my-app-warn,
            ),
        )
);

@include mat.all-component-themes($my-app-theme);

html,
body {
    height: 100%;
}

body {
    margin: 0;
    background-color: #f9f7f6;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-vertical-stepper-header {
    pointer-events: none !important;
}

.mat-vertical-content-container {
    padding-top: 20px;
}

.promo-primary {
    height: 420px;
}

app-campaign-header .promo-primary {
    height: 190px;
}

.aside-dropdown .button {
    max-width: 100%;
}

body[dir='rtl'] {
    .main-menu__item:not(:last-of-type) {
        margin-right: 0;
        margin-left: 30px
    }

    .about-us-text li {
        text-align: right;
    }

    .aside-dropdown__item li {
        text-align: right;
    }

    .header-logo {
        margin-left: 0;
        margin-right: 15px;
    }

    .dropdown-trigger {
        margin-left: 15px;
        margin-right: 0;
    }

    .lang-select__sub-list {
        text-align: right;

        &::before {
            left: auto !important;
            right: 33px !important;
            transform: rotate(90deg) !important;
        }
    }

    .row offset-margin about-us-text,
    .footer-logo,
    .footer__title,
    .footer-contacts, .footer-menu, .footer-copyright {
        text-align: right !important;
    }

    .slider__prev fa-icon,
    .slider__next fa-icon {
        transform: rotate(180deg);
    }

    .slider__prev {
        margin-left: 17px;
        margin-right: 0;
    }

    .footer-privacy {
        text-align: left !important;
    }

    p {
        text-align: right;
    }

    .mat-mdc-form-field {
        direction: rtl;
    }

    .mat-mdc-slide-toggle {
        direction: rtl;
        display: block;
    }
}

.mat-mdc-form-field {
    display: block;
}

mat-form-field.full-width {
    width: 100%;
}

:root {
    --mdc-filled-text-field-input-text-color: #333;
}

.mat-mdc-form-field-focus-overlay {
    background-color: initial !important;
}

.country-selector {
    width: 100px !important;
    padding-top: 18px !important;
    margin-left: -16px !important;
    background-position: right 26px !important;
}
